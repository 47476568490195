<template>
  <div class="close-btn" @click="goBack()">
    <div class="closebtn"><span class="txt">X</span></div>
  </div>
</template>

<script>
export default {
  emits: ["closeit"],
  methods: {
    goBack() {
      this.$emit("closeit");
    },
  },
};
</script>
<style scoped>
.close-btn {
  position: relative;
  justify-content: right;
  right: 5px;
  float: right;
  cursor: pointer;
  top: -2px;
}

.closebtn {
  position: relative;
  top: -2px;
  border: 2px solid #f53939;
  border-radius: 25px;
  left: 5px;
  width: 22px;
  height: 22px;
  text-align: center;
  background-color: #f53939;
  color: white;
  margin: 0 auto !important;
}

.closebtn .txt {
  position: relative;
  font-size: 14px;
  top: -1px;
}

.closebtn:hover {
  box-shadow: 0px 1px 2px 0px red;
  background-color: crimson;
  color: white;
}

@media only screen and (max-width: 600px) {
  .closebtn .txt {
    position: relative;
    top: 0px;
  }
}
</style>
