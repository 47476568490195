<template>
  <div class="menu-item">
    <div class="singlemenu" v-if="withsubmenu == false">
      <router-link to="/">
        <i class="menu-icon" :class="menuicon"></i>
        &nbsp;
        <div class="item-name">{{ menuname }}</div>
      </router-link>
    </div>

    <div v-if="withsubmenu == true">
      <div class="menu-head" @click="toggleSubmenu">
        <i class="menu-icon" :class="menuicon"></i>
        &nbsp;
        <div class="item-name">{{ menuname }}</div>
        <i class="down-arrow-icon"><span v-html="submenuarrow"></span></i>
      </div>

      <!-- Sub-Menu -->
      <transition name="menu-ani">
        <div class="submenu-list" v-if="showsubmenu">
          <div
            class="sub-menu-item"
            v-for="smenu in submenu[0]"
            :key="smenu.menuid"
          >
            <router-link :to="smenu.menulink">
              <div class="sub-item-head">
                <i class="fa-solid fa-circle submenu-icon"></i>
                &nbsp;
                <div class="item-name">{{ smenu.menuname }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  props: ["menuname", "menuicon", "submenu", "withsubmenu"],
  emits: ["submenuclick"],
  data() {
    return {
      showsubmenu: false,
      submenuarrow: "<i class='fa-solid fa-circle-chevron-right'></i>",
    };
  },
  methods: {
    toggleSubmenu() {
      this.showsubmenu = !this.showsubmenu;
      if (this.showsubmenu) {
        this.submenuarrow = "<i class='fa-solid fa-circle-chevron-down'></i>";
      } else {
        this.submenuarrow = "<i class='fa-solid fa-circle-chevron-right'></i>";
      }
    },
  },
};
</script>
<style scoped>
.menu-item {
  position: relative;
  display: block;
  height: fit-content;
  left: 0px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.singlemenu {
  position: relative;
  display: block;
  color: var(--theme-color);
  height: 30px;
}

.singlemenu a {
  position: relative;
  display: block;
  color: var(--theme-color);
  text-decoration: none;
  padding: 5px;
  height: 30px;
}

.singlemenu a:hover {
  cursor: pointer;
  background-color: var(--theme-color);
  color: white;
}

.singlemenu a .menu-icon {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: var(--theme-color);
  width: 35px;
  top: 2px;
  left: 5px;
}

.singlemenu a .item-name {
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  top: 0px;
  left: 15px;
}

.singlemenu a:hover .menu-icon {
  color: white;
}

.menu-head {
  position: relative;
  display: block;
  color: var(--theme-color);
  padding: 5px;
  height: 30px;
  width: 100%;
  left: 0px;
}

.menu-head:hover {
  cursor: pointer;
  background-color: var(--theme-color);
  color: white;
}

.menu-head .menu-icon {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: var(--theme-color);
  width: 35px;
  top: 2px;
  left: 5px;
}

.menu-head .down-arrow-icon {
  position: relative;
  float: right;
  right: 15px;
  top: 5px;
  font-size: 14px;
  text-align: right;
  margin-left: 25px;
}

.menu-head .item-name {
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  top: 0px;
  left: 15px;
}

.menu-head:hover .menu-icon {
  color: white;
}

.sub-menu-item {
  position: relative;
  display: block;
  cursor: pointer;
  left: 6px;
  font-size: 14px;
  width: 100%;
  color: var(--themesubmenu);
}

.sub-menu-item:hover {
  background-color: var(--themesubmenu);
  color: white;
}

.sub-menu-item a {
  position: relative;
  display: block;
  padding: 8px;
  text-decoration: none;
  color: var(--themesubmenu);
}
.sub-menu-item a:hover {
  background-color: var(--themesubmenu);
  color: white;
}

.sub-menu-item .item-name {
  position: relative;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  top: 0px;
  left: 25px;
}
.sub-menu-item .sub-item-head {
  padding: 2px;
}

.submenu-icon {
  position: relative;
  font-size: 8px;
  top: -2px;
  left: 5px;
}
.sub-menu-item .router-link-exact-active {
  background-color: var(--theme-hovercolor);
}

.menu-ani-enter-active {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.menu-ani-leave-active {
  transition: transform 0.3s ease-in, opacity 0.28s ease-out;
}

.menu-ani-enter-from {
  transition: none;
}

.menu-ani-enter-from,
.menu-ani-leave-to {
  transform: translateY(-30px) scale(0.96);
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .singlemenu a .menu-icon {
    font-size: 16px;
    width: 25px;
    top: 2px;
    left: 2px;
  }
  .menu-head .menu-icon {
    font-size: 16px;
    width: 25px;
    top: 2px;
    left: 2px;
  }
}
</style>
