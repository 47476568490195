<!-- @format -->

<template>
  <div class="billtrackdiv" v-show="showBox">
    <div class="title-bar">
      <div class="title-text">Item Details</div>
      <div class="close-btn" @click="hideBill">
        <div class="closebtn"><span class="txt">X</span></div>
        <!-- <span class="fas fa-times-circle"></span> -->
      </div>
    </div>
    <div class="contents">
      <div>
        Invoice No:
        <span class="bold">{{ Ser + "-" + Docno }}</span>
        &nbsp;&nbsp;&nbsp; Outlet: <span class="bold">{{ Outletname }}</span>
      </div>
      <br />
      <div class="table-area">
        <card-loader v-if="loading"></card-loader>
        <table class="table" v-else>
          <thead>
            <tr>
              <th class="toleft">Item</th>
              <th class="toright">Quantity</th>
              <th class="toright">Rate</th>
              <th class="toright">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tabledata" :key="row.docno">
              <td>{{ row.pname }}</td>
              <td class="toright">{{ row.qty }}</td>
              <td class="toright">{{ row.rate }}</td>
              <td class="toright">{{ row.amount }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="1" class="toright">Total</td>
              <td class="toright">{{ getdetTotal(tabledata, "qty") }}</td>
              <td class="toright">{{ getdetTotal(tabledata, "rate") }}</td>
              <td class="toright">{{ getdetTotal(tabledata, "amount") }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div class="bgdark" v-show="showBox"></div>
</template>
<script>
import axios from "axios";
export default {
  emits: ["on-close"],
  data() {
    return {
      loading: false,
      tabledata: [],
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      showBox: false,
      Tc: "",
      Ser: "",
      Docno: "",
      Outletid: "",
      Outletname: "",
    };
  },
  methods: {
    async getbillList() {
      this.loading = true;
      await axios
        .post("/reports/billwisereport.php", {
          optn: "BillDet",
          tc: this.Tc,
          ser: this.Ser,
          docno: this.Docno,
          outletid: this.Outletid,
        })
        .then((response) => {
          console.log(response);
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.tabledata = resp.List;
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });
      this.loading = false;
    },
    getdetTotal(arr, field) {
      var i = 0;
      var total = 0;
      for (i = 0; i < arr.length; i++) {
        var myval = arr[i];
        total += parseFloat(myval[field]);
      }
      return total.toFixed(2);
    },
    async showBill(row) {
      this.Tc = row.tc;
      this.Ser = row.ser;
      this.Docno = row.docno;
      this.Outletid = row.outletid;
      this.Outletname = row.outletname;
      await this.getbillList();
      this.showBox = true;
    },
    hideBill() {
      this.showBox = false;
    },
  },
  mounted() {},
};
</script>

<style src="./Tracks/css/track.css" scoped></style>

<style scoped>
.billtrackdiv {
  position: fixed;
  max-width: 100%;
  width: 100%;
  height: fit-content;
  max-height: 450px;
  z-index: 9999;
  background-color: white;
  border-radius: 5px;
  right: 0px;
  top: 55px;
  bottom: 0px;
  left: 0px;
  margin: 0px auto !important;
}
.table-area {
  border: none !important;
  border-radius: 0px;
}
.table {
  border: 1px solid black;
}
.table tr td,
.table tr th {
  font-size: 16px !important ;
}
</style>
