<template>
  <div class="myfooter">
    <div class="user-details">
      <label>User : {{ userName }}</label>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userName: this.$store.state.userName,
    };
  },
};
</script>

<style scoped>
.myfooter {
  position: fixed;
  display: block;
  width: 100%;
  height: 25px;
  background-color: white;
  bottom: 0px;
  padding: 0px;
  box-shadow: 1px 0px 5px 2px lightgray;
  z-index: 10;
}
.user-details {
  position: relative;
  display: inline-block;
  left: 10px;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
}
.user-details label {
  position: relative;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}
</style>
