<template>
  <div class="report-viewer">
    <div class="title-bar">
      <button type="button" class="btn btn-primary" @click="printReport">
        Print
      </button>
      <button type="button" class="btn btn-info" @click="exportPreviewPdf()">
        PDF
      </button>
      <button type="button" class="btn btn-danger" @click="closePreview">
        Close
      </button>
    </div>
    <div class="preview-area">
      <vue3-simple-html2pdf
        ref="printpreview"
        :options="printpdfOptions"
        :filename="printFile"
      >
        <div class="printable" id="printable">
          <slot></slot>
        </div>
      </vue3-simple-html2pdf>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["close-window"],
  props: {
    PaperSize: {
      type: String,
      default: "a4",
    },
    Orientation: {
      type: String,
      default: "p",
    },
    FileName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      printFile: this.FileName,
      printpdfOptions: {
        margin: 2,
        image: {
          type: "jpeg",
          quality: 4,
        },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: this.PaperSize,
          orientation: this.Orientation,
        },
      },
    };
  },
  methods: {
    closePreview() {
      this.$emit("close-window");
    },
    async exportPreviewPdf() {
      await this.$refs.printpreview.download();
    },
    printReport() {
      var prtcss =
        "<link rel='stylesheet' href='print.css'><link rel='stylesheet' href='printpreview.css'>";
      var recdata = document.getElementById("printable").innerHTML;
      var popupWin = window.open(
        "",
        "_blank",
        "width=1048,height=768,location=no,left=200"
      );
      popupWin.document.open();
      popupWin.document.write(
        "<html><head><title>Print Report</title>" +
          prtcss +
          "</head><body onload='window.print();window.close();'>"
      );
      popupWin.document.write(recdata);
      popupWin.document.write("</body></html>");
      popupWin.document.close();
    },
  },
};
</script>
<style scoped>
.report-viewer {
  position: fixed;
  top: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 9999;
  padding: 0px;
}

.title-bar {
  position: relative;
  top: 0px;
  display: block;
  width: 100%;
  padding: 2px;
  background-color: white;
  text-align: center;
}

.preview-area {
  position: relative;
  margin: 0px auto !important;
  width: 95%;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
  background-color: gray;
  padding-top: 15px;
  padding-bottom: 15px;
}

.printable {
  position: relative;
  background-color: white;
  /* max-width: 95%; */
  width: fit-content;
  height: fit-content;
  /* max-width: 8in;
  max-height: 11in; */
  margin: 0px auto !important;
}
</style>
<style>
@import url("printpreview.css");
</style>
