<template>
  <div class="mysidebar">
    <div class="menu-list">
      <!-- Menu Items -->
      <menu-item
        :menuname="'Dashboard'"
        :menuicon="'fa-solid fa-house'"
        :withsubmenu="false"
      ></menu-item>
      <menu-item
        v-for="(menu, index) in mainmenu"
        :key="index"
        :menuname="menu.menuname"
        :menuicon="menu.menuicon"
        :submenu="menu.submenu"
        :withsubmenu="true"
        @submenuclick="subclick"
      ></menu-item>

      <!-- Menu Item List -->
    </div>
  </div>
</template>
<script>
import MenuItem from "./MenuItem";
import axios from "axios";

export default {
  components: {
    MenuItem,
  },
  data() {
    return {
      mainmenu: [],
    };
  },
  methods: {
    subclick(myname) {
      console.log(myname);
    },
    getMenu() {
      axios
        .post("menu.php", {
          optn: "getmenu",
          userId: this.$store.state.userId,
        })
        .then((response) => {
          this.mainmenu = response.data.Menu;
        });
    },
  },
  mounted() {
    this.getMenu();
  },
};
</script>

<style>
@import "../css/base.css";
</style>
<style scoped>
.mysidebar {
  position: fixed;
  /* width: 200px; */
  min-width: 55px;
  width: 55px;
  max-width: 230px;
  height: 100vh;
  clear: both;
  float: left;
  background-color: white;
  transition: ease-in-out 0.3s all;
  overflow-x: hidden;
  z-index: 10;
  top: 0px;
  box-shadow: 0px 0px 4px 1px lightgray;
}
.mysidebar:hover {
  width: 230px;
}

.menu-list {
  position: relative;
  display: block;
  width: calc(230px - 5px);
  height: fit-content;
  top: 55px;
  margin-bottom: 150px;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 600px) {
  .mysidebar {
    min-width: 40px;
    width: 40px;
  }
}
</style>
