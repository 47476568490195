<!-- @format -->

<template>
  <div
    class="alert-box d-flex align-items-center"
    :class="alertType"
    role="alert"
    v-if="showBox"
  >
    <div @click="hideAlert">
      <i class="fa-solid fa-times-circle closeicon"></i>
    </div>
    <div>
      <span class="fa-solid" :class="geticonClass"></span>&nbsp;&nbsp;&nbsp;
      <span
        style="
          position: relative;
          left: -8px;
          font-size: 18px;
          font-weight: bold;
        "
        v-if="alertType == 'danger'"
        >Alert</span
      ><br v-if="alertType == 'danger'" />
      <span class="alertxt" v-html="alertText"></span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["alertType", "alertText"],
  data() {
    return {
      alertClass: this.alertType,
      showBox: false,
    };
  },
  methods: {
    showAlert() {
      this.showBox = true;
    },
    hideAlert() {
      this.showBox = false;
    },
  },
  computed: {
    geticonClass: function () {
      var alerticon = "";
      if (this.alertType == "success") {
        alerticon = "fa-floppy-disk";
      }
      if (this.alertType == "danger") {
        alerticon = "fa-triangle-exclamation";
      }
      if (this.alertType == "warn") {
        alerticon = "fa-circle-exclamation";
      }
      if (this.alertType == "info") {
        alerticon = "fa-circle-info";
      }

      return alerticon;
    },
  },
};
</script>

<style scoped>
.alert-box {
  position: fixed;
  top: 65px;
  float: right;
  right: 35px;
  width: 350px;
  height: fit-content;
  z-index: 99999;
  padding: 15px;
  border-radius: 6px;
  color: white;
}
.danger {
  border: 2px solid #a80808;
  background-color: #d34747;
}

.success {
  border: 2px solid #20751d;
  background-color: #198754;
}
.warn {
  border: 2px solid var(--warn-dark);
  background-color: var(--warn);
}
.info {
  border: 2px solid #1d4075;
  background-color: #255acc;
}
.closeicon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 18px;
}
.closeicon:hover {
  cursor: pointer;
  color: rgb(230, 214, 198);
}
.alertxt {
  word-break: break-all;
  word-spacing: normal;
}

@media only screen and (max-width: 600px) {
  .alert-box {
    width: 150px;
  }
}
</style>
